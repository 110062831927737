/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
import { createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["accept"];
import { useStore } from "vuex";
import { Upload } from '@/js/uppy';
/**
 * 基礎上傳元件
 *
 * - 使用包裝好的Uppy upload
 * - 控制上傳時畫面相關樣式
 */

export default {
  __name: 'MBaseUploader',
  props: {
    itemAspectRatio: Number,

    /**
     * 預設值
     * @property {String} defaultValues[0].fileName
     * @property {String} defaultValues[0].src
     */
    // 上傳api url
    ajaxUrl: String,
    // 上傳類型
    accept: String,
    // 可否多選
    multiple: Boolean,
    // uppy設定
    uppyConfig: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  emits: ['complete', 'success'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var store = useStore();
    var refRoot = ref(null);
    var refInput = ref(null);
    var instance;

    function addFile() {
      instance.toUpload();
    }

    function init() {
      if (!props.ajaxUrl) {
        console.log('請指定上傳api url');
        return;
      }

      if (!refInput.value || !refRoot.value) {
        return;
      }

      instance = Upload.init(refRoot.value, {
        fileInput: refInput.value,
        ajaxUrl: props.ajaxUrl,
        multiple: props.multiple,
        uppyConfig: props.uppyConfig,
        onFileInputChange: function onFileInputChange() {},
        onUpload: function onUpload(data) {
          store.state.appGlobalSpinnerShow = true;
        },
        onUploadSuccess: function onUploadSuccess(data) {
          emit('success', data);
        },
        onRestrictionErr: function onRestrictionErr(err) {
          appSwalCustomDialog.alert({
            title: '訊息提示',
            content: err
          });
        },
        // 上傳完成
        onComplete: function onComplete(data) {
          emit('complete', data);
          store.state.appGlobalSpinnerShow = false;
        }
      });
    }

    onMounted(function () {
      init();
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "refRoot",
        ref: refRoot,
        class: "m-base-uploader",
        style: _normalizeStyle({
          '--m-base-uploader-item-aspect-ratio': __props.itemAspectRatio || null
        })
      }, [_createElementVNode("input", {
        ref_key: "refInput",
        ref: refInput,
        accept: __props.accept,
        style: {
          "display": "none"
        },
        type: "file"
      }, null, 8, _hoisted_1), _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({
        addFile: addFile
      })))], 4);
    };
  }
};