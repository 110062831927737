import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import ProgressBar from '@uppy/progress-bar';
import zh_TW from '@uppy/locales/lib/zh_TW';
import '@uppy/core/dist/style.css';
import '@uppy/progress-bar/dist/style.css';

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (c) {
    return (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16);
  });
} // 載入Uppy預設值


function loadUppyInitial(uppyInstance, dataArr) {
  var psArr = [];
  dataArr.forEach(function (dataObj) {
    var uppyFileId = uppyInstance.addFile({
      name: dataObj.orig_name,
      // file name
      type: '',
      // file type
      data: '',
      // file blob
      meta: {
        // 後端給的檔名
        orig_name: dataObj.orig_name,
        src: dataObj.src,
        // 預設資料flag
        onServer: true
      },
      source: 'Local' // optional, determines the source of the file, for example, Instagram.

    });
    var fileFromUppy = uppyInstance.getFile(uppyFileId);
    uppyInstance.setFileState(uppyFileId, {
      progress: {
        uploadComplete: true,
        uploadStarted: true
      }
    });
    uppyInstance.emit('upload-success', fileFromUppy, 'success');
  });
  uppyInstance.setOptions({
    autoProceed: true
  });
}

export var Upload = function () {
  /**
     * @param fileInput input元素
     * @param {Object} setting - 設定
     * @property {Object} setting.uppyConfig - uppy套件設定 (可用選項: https://uppy.io/docs/uppy/#Options )
     * @property {Boolean} setting.multiple - 多選 (預設: false)
     * @property {Array} setting.defaultValues - 預設值陣列
     * @property {Array} setting.ajaxUrl - 預設值陣列
     * @property {Function} setting.onUploadSuccess - 上傳成功回呼
     * @property {Function} setting.onRestrictionErr - 上傳不符合限制回呼
     * @property {Function} setting.onUpload - 上傳開始回呼
     * @property {Function} setting.onComplete - 執行完成回呼
     */
  function init(areaEl, setting) {
    var $areaEl = areaEl ? $(areaEl) : $('<div>');
    var backupIpt = document.createElement('input');
    backupIpt.type = 'file';
    var fileInput = setting.fileInput ? $(setting.fileInput)[0] : backupIpt;
    if (setting.multiple) fileInput.multiple = 'multiple'; // 預設值

    var defaultValues = setting.defaultValues || [];
    var defaultConfig = {
      locale: zh_TW,
      debug: false,
      autoProceed: false,
      // 限制
      restrictions: {}
    };
    var config = $.extend(true, {}, defaultConfig, setting.uppyConfig || {});
    var uppy = new Uppy(config);
    if (config.restrictions.allowedFileTypes) fileInput.accept = config.restrictions.allowedFileTypes.join(',');

    function toUpload() {
      fileInput.click();
    }

    uppy.use(XHRUpload, {
      // Ajax 上傳路徑
      endpoint: setting.ajaxUrl,
      formData: true,
      fieldName: 'files[]'
    }); // 上傳成功

    uppy.on('upload-success', function (file, response) {
      var isDefaultFile = file.meta.onServer;
      var responseData = response && response.body ? response.body : {};
      var origName = responseData.orig_name || file.meta.orig_name; // 前端程式

      var randomId = uuidv4();
      var uppyId = file.id; // 這是套件內部給的ID

      var url = isDefaultFile ? file.meta.src : response.uploadURL;
      var fileName = file.name;
      file.meta.randomId = randomId;

      if (typeof setting.onUploadSuccess == 'function') {
        var toSend = {
          randomId: randomId,
          uppyId: uppyId,
          fileName: fileName,
          origName: origName,
          file: file,
          url: url
        };
        setting.onUploadSuccess(toSend);
      }
    }); // 前端程式

    uppy.use(ProgressBar, {
      target: 'body',
      fixed: true,
      hideAfterFinish: true
    });
    uppy.on('upload', function (data) {
      if (typeof setting.onUpload == 'function') setting.onUpload(data);
    });
    uppy.on('file-added', function () {
      console.log('added');
    }); // it’s probably a good idea to clear the `<input>`
    // after the upload or when the file was removed
    // (see https://github.com/transloadit/uppy/issues/2640#issuecomment-731034781)

    uppy.on('file-removed', function () {
      fileInput.value = null;
    });
    uppy.on('complete', function (result) {
      fileInput.value = null;

      if (typeof setting.onComplete == 'function') {
        setting.onComplete(result); // 不在uppy保留檔案，讓每次上傳都是乾淨的

        uppy.removeFiles(uppy.getFiles().map(function (item) {
          return item.id;
        }));
      }
    });
    fileInput.addEventListener('change', function (event) {
      var files = Array.from(event.target.files);
      if (typeof setting.onFileInputChange == 'function') setting.onFileInputChange();
      files.forEach(function (file) {
        try {
          uppy.addFile({
            source: 'Local',
            name: file.name,
            type: file.type,
            data: file
          });
        } catch (err) {
          if (err.isRestriction) {
            // handle restrictions
            if (typeof setting.onRestrictionErr == 'function') {
              console.log('uppy', uppy);
              setting.onRestrictionErr(err);
            } else {
              console.error('err', err);
            }
          } else {
            // handle other errors
            console.error('err', err);
          }
        }
      });
    });
    loadUppyInitial(uppy, defaultValues);
    return {
      toUpload: toUpload,
      uppy: uppy
    };
  }

  return {
    init: init
  };
}();